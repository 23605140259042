import React from "react";
import { Dispatch } from "../../types/store-types";
import styles from './MessengersItem.module.scss'
import gs from "../../Layout.module.scss";
import SelectInput from "../inputs/SelectInput";
import { Lang } from "../../types/common-types";
import Applicant from "../../types/applicant";
import { Fields } from "../../content/fields";
import { Errors } from "../../content/errors";
import LabeledInput from "../inputs/LabeledInput";
import { actions } from "../../store/store";
import PhoneInput from "../inputs/PhoneInput";

interface Props {
    lang: Lang;
    state: Applicant;
    invalidFields: (string | false)[];
    dispatch: Dispatch;
    disabled: boolean;
    index: number
}

export default function MessengersItem({ lang, state, invalidFields, dispatch, disabled, index }: Props) {
    const MessengersOptions = [
        { value: Fields.telegram.value, label: Fields.telegram?.label[lang] },
        { value: Fields.whatsapp.value, label: Fields.whatsapp?.label[lang] },
        // { value: Fields.viber.value, label: Fields.viber?.label[lang] },
    ];
    return <>
        <div className={gs.col6}>
            <SelectInput
                name={'messengersType'}
                disabled={disabled}
                options={MessengersOptions.filter((item)=> {
                    return !state.messengers.find((e)=> {
                        return e.type === item.value
                    })
                })}
                changeHandler={(e) => {
                    dispatch({
                        type: actions.MESSENGERS,
                        data: index < state.messengers.length
                            ? state.messengers.map((item, i) => {
                                return (index === i) ? {
                                    type: e.value,
                                    description: e.label,
                                    link: state.messengers[index]?.link ?? "",
                                } : item
                            }) : state.messengers.concat({
                                type: e.value,
                                description: e.label,
                                link: state.messengers[index]?.link ?? "",
                            })
                    })
                }}
                value={{
                    label: state?.messengers[index]?.description,
                    value: state?.messengers[index]?.type
                } ?? undefined}
                invalidFields={invalidFields}
                placeholder={Fields.messengerType?.label[lang]}
                errorBorder={invalidFields.includes("messengersType")}
                error={invalidFields.includes("messengersType") && Errors.messengersType[lang]}
            ></SelectInput>
        </div>
        <div className={gs.col6}>
            {state?.messengers[index]?.type === '9' ||
            state.whatsappCheck && state?.messengers[index]?.type === '10' ||
            state.viberCheck && state?.messengers[index]?.type === '11' ?
                <LabeledInput
                    name={`messengersLink${index}`}
                    disabled={disabled}
                    label={state?.messengers[index]?.type === '9' ? '@username' : Fields.phoneNumber?.label[lang]}
                    onChange={(e) => {
                        const telegram = e?.target.value.replace(/[^A-Za-z0-9_]/gi, '')
                        dispatch({
                            type: actions.MESSENGERS,
                            data: index < state.messengers.length
                                ? state.messengers.map((item, i) => {
                                    return (index === i) ? {
                                        type: state.messengers[index]?.type,
                                        description:state.messengers[index]?.description,
                                        link: telegram,
                                    } : item
                                }) : state.messengers.concat({
                                    type: state.messengers[index]?.type,
                                    description:state.messengers[index]?.description,
                                    link: telegram
                                })
                        })
                    }}
                    value={
                        state.telegramCheck && state?.messengers[index]?.type === '9' ||
                        state.whatsappCheck && state?.messengers[index]?.type === '10' ||
                        state.viberCheck && state?.messengers[index]?.type === '11' ?
                            state?.messengers[index]?.link : 
                            `@${state?.messengers[index]?.link}`
                        }
                    errorBorder={invalidFields.includes(`messengersLink${index}`)}
                    error={invalidFields.includes(`messengersLink${index}`) && Errors.messengersLink[lang]}
                />
            : 
            <PhoneInput
                name={`messengersLink${index}`}
                disabled={disabled}
                label={Fields.phoneNumber?.label[lang]}
                onChange={(e) => {
                    dispatch({
                        type: actions.MESSENGERS,
                        data: index < state.messengers.length
                            ? state.messengers.map((item, i) => {
                                return (index === i) ? {
                                    type: state.messengers[index]?.type,
                                    description:state.messengers[index]?.description,
                                    link: e,
                                } : item
                            }) : state.messengers.concat({
                                type: state.messengers[index]?.type,
                                description:state.messengers[index]?.description,
                                link: e
                            })
                    })
                }}
                value={state?.messengers[index]?.link}
                errorBorder={invalidFields.includes(`messengersLink${index}`)}
                error={invalidFields.includes(`messengersLink${index}`) && Errors.messengersLink[lang]}
            />}
        </div>
    </>
}